//.grid-container {
//    .paging.paging-fixed {
//        position: initial !important;
//    }
//}
.textCellHidden{
    text-overflow: ellipsis;
    overflow: hidden;
}
@media (max-width: 580px) {
    .W05F0005-Form{
            width: 100% !important;
    }
    .W05F0005-Date{
            display: flex !important;
    }
  }


